import { ERROR } from "constant/message";
import {
  MODULE_DEMANDE_IDENTIFICATION,
  MODULE_ANIMAL,
  MODULE_ROLE,
  MODULE_USER,
  MODULE_LOT,
  MODULE_CAMPAGNE,
  MODULE_FERME,
  MODULE_ESPECE,
} from "constant/moduleInfo";

export default function useArrayOperation() {
  const sortArray = (array, labelName) => {
    try {
      const result = array
        .map((i) => {
          const item = {
            ...i,
            ...{ label: i[labelName] },
          };
          return item;
        })
        .sort((a, b) => {
          // Ordonne le table par ordre alphabétique
          const nameA = a.label.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label.toUpperCase(); // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      return result;
    } catch (error) {
      console.log("sortArray error", error, array);
      return ERROR;
    }
  };
  // Remove duplicated regions/ departement in array of lieu
  const removeDuplicateItem = (array, labelName) => {
    try {
      const dataArr = array.map((i) => {
        const item = {
          ...i,
          ...{ label: i[labelName] },
        };
        return item;
      });
      const uniqueMap = new Map();

      // Filter the array, keeping only the first occurrence of each item
      const newArray = dataArr.reduce((accumulator, current) => {
        // Use some identifier, like 'name' property, to determine uniqueness
        let identifier = "";
        identifier = current.label; // Change this based on your requirement

        // If the identifier is not already in the Map or object, add it and push the item to the accumulator
        if (!uniqueMap.has(identifier)) {
          uniqueMap.set(identifier, true);
          // Or, if using an object: uniqueItems[identifier] = true;
          accumulator.push(current);
        }

        return accumulator;
      }, []);
      const result = sortArray(newArray, "label");

      return result;
    } catch (error) {
      console.error("removeDuplicateItem error", error);
      return error;
    }
  };
  const customArrItem = (array, moduleName) => {
    try {
      const result = array.map((i) => {
        let newKeys = {};
        if (moduleName === MODULE_FERME) {
          newKeys = { isAnimal: true, isLot: true };
        } else if (moduleName === MODULE_LOT) {
          newKeys = { isAnimal: true };
        } else if (moduleName === MODULE_CAMPAGNE) {
          newKeys = { isLot: true, nom: i.nomCampagn };
        } else if (moduleName === MODULE_USER) {
          newKeys = {
            // isRole: true,
            isReset: true,
            isUser: true,
            userName: `${i.prenom} ${i.nom}`,
            // isDetail: true,
            isEdit: true,
          };
        } else if (moduleName === MODULE_ROLE) {
          newKeys = {
            isRole: true,
          };
        } else if (
          moduleName === MODULE_ANIMAL ||
          moduleName === MODULE_ESPECE ||
          moduleName === MODULE_DEMANDE_IDENTIFICATION
        ) {
          newKeys = { isDetail: true };
        }
        const item = {
          ...i,
          ...newKeys,
        };
        return item;
      });
      return result;
    } catch (error) {
      console.log("customArrItem error", error);
      return ERROR;
    }
  };
  function removeItemFromArray(array, itemToRemove) {
    // Return a new array with the item removed if it exists
    return array.filter((item) => item !== itemToRemove);
  }
  return { removeDuplicateItem, customArrItem, sortArray, removeItemFromArray };
}
