// src/AnimalProfile.js
import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import AnimalDetails from "layouts/gestionAnimaux/component/AnimalDetails";
import { AnimalContext } from "context/animalContext";
import AnimalPerson from "layouts/gestionAnimaux/component/AnimalPerson";

const AnimalProfile = () => {
  const { animal } = useContext(AnimalContext);
  const [personList, setPersonList] = useState([]);

  useEffect(() => {
    const allPerson = [
      {
        type: "Propriétaire",
        info: animal.properietaire,
      },
      {
        type: "Eleveur",
        info: animal.eleveur,
      },
      {
        type: "Identificateur",
        info: animal.user,
      },
    ];
    setPersonList(allPerson);
  }, [animal]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AnimalDetails />
      </Grid>
      <Grid container spacing={2}>
        {personList.map((i) => (
          <Grid item xs={12} sm={6} md={4}>
            <AnimalPerson person={i} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default AnimalProfile;
