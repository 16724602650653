import { TITLE_LIEU, MODULE_LIEU, MODULE_DONNEE_REF, MODULE_ESPECE } from "constant/moduleInfo";
import React, { useEffect, useMemo, useState } from "react";
import servicesAPI from "services/servicesAPI";
import LayoutContext from "context/LayoutContext";
import LoadComponent from "components/LoadComponent";
import DataTableComponent from "components/DataTableComponent";
import useTableLieu from "layouts/donneeReference/rubrique/gestionLieu/hooks/useTableLieu";
import permissionFetcher from "utils/permissionFetcher";
import { useNavigate, useLocation } from "react-router-dom";
import { NO_DATA_FOUND, ERROR, NO_PERMISSION } from "constant/message";
import { LAST_PATH } from "constant";
import useArrayOperation from "hooks/useArrayOperation";
import { checkRoleExist } from "utils/userInfos";
import { SUPER_ADMIN_ROLE } from "constant/role";
import MDButton from "components/MDButton";
import { LieuContext } from "context/AppContext";

import EffectifPrevForm from "layouts/donneeReference/rubrique/gestionLieu/components/EffectifPrevForm";
import EffectifPrevAction from "layouts/donneeReference/rubrique/gestionLieu/components/EffectifPrevAction";
import { normalizeText } from "utils/textOperation";

function GestionLieu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [permissionModule, setPermissionModule] = useState([]);
  const [listData, setListData] = useState([]);
  const [showActionEffectif, setshowActionEffectif] = useState(false);
  const [listEspece, setListEspece] = useState([]);
  const [openEffectifPrev, setOpenEffectifPrev] = useState(false);
  const [openEffectifPrevAction, setOpenEffectifPrevAction] = useState(false);
  const { isAlert, isLoading, message, alertSeverity, handleLoad, postData, getData } =
    servicesAPI();
  const { columns, rows, renderDataTable, selectedItem, actionOption, onSelectedRow } =
    useTableLieu();
  const { customArrItem, removeDuplicateItem } = useArrayOperation();
  const getAllData = async () => {
    const fetchData = await getData(MODULE_LIEU);
    // const customArr = customArrItem(fetchData, MODULE_Lieu);
    if (fetchData !== ERROR) {
      const result = fetchData.sort((a, b) => new Date(b.dateDebut) - new Date(a.dateDebut));
      const customArr = customArrItem(result, MODULE_LIEU);
      if (customArr.length > 0) {
        // console.log("ddd", customArr);
        setListData(customArr);
        renderDataTable(customArr);
      } else {
        const loadData = {
          isAlert: true,
          message: NO_DATA_FOUND,
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  };
  const handleCloseEffectifPrevAction = () => {
    setOpenEffectifPrevAction(false);
  };
  const handleCloseEffectifPrev = () => {
    setOpenEffectifPrev(false);
    onSelectedRow({}, "");
  };
  const saveEffectifData = async (data) => {
    // console.log("ddd", data);
    const fetchData = await postData("campagnEffectif", data);
    if (fetchData !== ERROR) {
      setTimeout(() => {
        handleCloseEffectifPrev();
        getAllData();
      }, 200);
    }
  };
  const getPermission = () => {
    const permissionArray = permissionFetcher(MODULE_DONNEE_REF);
    if (permissionArray.find((i) => i.name.includes(`Consulter${MODULE_DONNEE_REF}`))) {
      const permission = permissionArray.filter((i) => i.name !== `Insert${MODULE_DONNEE_REF}`);
      setPermissionModule(permission);
      getAllData();
    } else {
      const loadData = {
        isAlert: true,
        message: NO_PERMISSION,
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    }
  };
  const getEspece = async () => {
    const fetchData = await getData(MODULE_ESPECE);
    let espece = [];
    if (fetchData !== ERROR) {
      espece = fetchData;
    }
    return espece;
  };
  useEffect(async () => {
    if (Object.keys(selectedItem).length !== 0) {
      console.log("eee", selectedItem);
      // setListEspece(especeData);
    }
  }, [selectedItem]);
  useEffect(async () => {
    getPermission();
    localStorage.setItem(LAST_PATH, location.pathname);
    const isSuperAdmin = checkRoleExist(SUPER_ADMIN_ROLE);
    // const isAdminG = checkRoleExist(ADMIN_PAYS_ROLE);
    // if (isSuperAdmin || isAdminG) {
    if (isSuperAdmin) {
      setshowActionEffectif(true);
      const especeData = await getEspece();
      setListEspece(especeData);
    }
  }, []);
  const handleSearch = (value) => {
    if (value === "") {
      renderDataTable(listData);
    } else {
      /* const filteredData = listData.filter(
        (item) =>
          item?.region?.toLowerCase().includes(value.toLowerCase()) ||
          item?.departement?.toLowerCase().includes(value.toLowerCase()) ||
          item?.commune?.toLowerCase().includes(value.toLowerCase())
      ); */
      const searchTerm = normalizeText(value);
      const filteredData = listData.filter((item) => {
        return (
          normalizeText(item?.region).includes(searchTerm) ||
          normalizeText(item?.departement).includes(searchTerm) ||
          normalizeText(item?.commune).includes(searchTerm)
        );
      });
      if (filteredData.length > 0) {
        renderDataTable(filteredData);
      } else {
        const loadData = {
          isAlert: true,
          message: NO_DATA_FOUND,
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  };

  const exportedData = () => {
    const listLieu = removeDuplicateItem(listData, "departement");
    // const ListDepartement = listLieu.map((i) => `${i.departement},${i.id}`);
    const ListDepartement = listLieu.map((i) => i.departement);

    const enteteFile = [...[""], ...ListDepartement];
    const especeData = listEspece.map((i) => {
      const item = [i.nomCourant, ...Array(ListDepartement.length).fill(0)];
      return item;
    });
    const worksheetData = [
      enteteFile,
      // ...dataGraph.datasets.map((dataset) => [dataset.label, ...dataset.data]),
      ...especeData,
    ];
    return worksheetData;
  };
  const contextValue = useMemo(() => {
    const value = {
      title: TITLE_LIEU,
      columns,
      rows,
      permissionModule,
      // saveData,
      module: MODULE_DONNEE_REF,
      selectedItem,
      actionOption,
      onSelectedRow,
      handleSearch,
      refreshData: getAllData,
    };
    return value;
  }, [columns, rows, permissionModule, selectedItem, actionOption]);
  const contextLieuValue = useMemo(() => {
    const value = {
      handleClose: handleCloseEffectifPrev,
      handleOpen: setOpenEffectifPrev,
      open: openEffectifPrev,
      handleCloseAction: handleCloseEffectifPrevAction,
      openAction: openEffectifPrevAction,
      selectedItem,
      listEspece,
      listLieu: listData,
      saveData: saveEffectifData,
      exportedData,
    };
    return value;
  }, [openEffectifPrev, openEffectifPrevAction, selectedItem, listEspece]);

  return (
    <div>
      {/* showActionEffectif && (
        <>
          <MDButton
            type="submit"
            variant="gradient"
            color="info"
            onClick={() => setOpenEffectifPrev(true)}
            style={{ marginRight: "10px" }}
          >
            Créer un effectif Prévisionnel
          </MDButton>
          <MDBox display="flex" alignItems="center" sx={{ marginRight: 0 }}>
            <ExcelExport
              exportedData={exportedData}
              fileName="Modèle effectif Prévisionnel"
              btnTitle="Exporter le Modèle"
            />
          </MDBox>
        </>
      ) */}
      <LayoutContext.Provider value={contextValue}>
        <DataTableComponent />
      </LayoutContext.Provider>
      <LieuContext.Provider value={contextLieuValue}>
        {showActionEffectif && (
          <MDButton
            type="submit"
            variant="gradient"
            color="info"
            onClick={() => setOpenEffectifPrevAction(true)}
            style={{ marginTop: 10 }}
          >
            Effectif Prévisionnel
          </MDButton>
        )}
        <EffectifPrevAction />
        <EffectifPrevForm />
      </LieuContext.Provider>
      <LoadComponent loadData={{ isAlert, isLoading, message, alertSeverity, handleLoad }} />
    </div>
  );
}

export default GestionLieu;
