import React, { useMemo } from "react";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";
import NewDemandeIdentificationComponent from "./component/NewDemandeIdentificationComponent";

function NewDemandeIdentification() {
  const {
    isAlert,
    isLoading,
    message,
    alertSeverity,
    handleLoad,
    getData,
    putData,
    postData,
    getDataUser,
  } = servicesAPI();
  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);

  const propsDataConfig = useMemo(() => {
    const value = {
      ApiService: { getData, putData, postData, getDataUser },
      handleLoad,
    };
    return value;
  }, []);
  return (
    <>
      <ModuleTitle title="Nouvelle Demande d'identification" />
      <BackButton />
      <NewDemandeIdentificationComponent propsDataConfig={propsDataConfig} />
      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default NewDemandeIdentification;
