// UserDetails.js
import React, { useContext, useEffect, useState } from "react";
import { TextField, Box, Typography } from "@mui/material";
import { UserContext } from "context/userContext";
// import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import getLieu from "services/lieuService";
import getEntity from "services/entityService";
import getTag from "services/tagService";
import MDButton from "components/MDButton";
import handleLieuChange from "helpers/lieuHelper";
import AutoCompleteComponent from "components/AutoCompleteComponent";
import { ERROR } from "constant/message";
import fetchCustomProfil from "helpers/profilHelper";
import handleSelectChange from "helpers/selectHelper";
import { useNavigate } from "react-router-dom";
import {
  SUPERVISEUR_ROLE,
  ADMIN_REGION_ROLE,
  ADMIN_DEPARTEMENTAL_ROLE,
  AGENT_ROLE,
  RESPONSABLE_FERME,
  ELEVEUR,
  PROPRIETAIRE,
} from "constant/role";
import { LAST_PATH } from "constant";
/* import {
  TYPE_DIRECTION,
  TYPE_STRUCTURE_PRIVEE,
} from "layouts/donneeReference/rubrique/gestionEntite/constant"; */

const UserDetails = () => {
  const { user, niveau, updateUserDetails, ApiService, handleLoad } = useContext(UserContext);
  const { getData, putData, postData } = ApiService;
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(user ?? {});
  const [listProfil, setListProfil] = useState([]);
  // const [listDirection, setListDirection] = useState([]);
  // const [listStructurePrivee, setListStructurePrivee] = useState([]);
  const [listEntity, setListEntity] = useState([]);
  const [listTag, setListTag] = useState([]);
  const [lieuClassified, setLieuClassified] = useState({});

  const goToMain = () => {
    const loadData = {
      isAlert: true,
      message: "Le compte a été crée avec succès",
      isLoading: false,
      alertSeverity: "success",
    };
    handleLoad(loadData);
    setTimeout(() => {
      const lastPath = localStorage.getItem(LAST_PATH);
      navigate(lastPath);
    }, 200);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log("dddda", name, value);
    let updatedUserDetails = {};
    if (["region", "departement", "commune"].includes(name)) {
      updatedUserDetails = handleLieuChange(userDetails, name, value);
    } else if (value.isSelect) {
      // if (["fonction ", "entity", "userTags"].includes(name)) {
      updatedUserDetails = handleSelectChange(userDetails, name, value);
    } else {
      updatedUserDetails = { ...userDetails, [name]: value };
    }
    setUserDetails(updatedUserDetails);
  };

  const handleUpdateUser = async () => {
    if (userDetails === "") {
      updateUserDetails(userDetails);
      const endpoint = "auth/register";
      const fetchData = await putData(endpoint, userDetails);
      if (fetchData !== ERROR) {
        console.log("Success");
      } else {
        console.log("Failed");
      }
    } else {
      console.log("userDetails", userDetails);
    }
  };
  const linkUserTag = async (tagName) => {
    const endpoint = `tags/${tagName}/link/user`;
    const dataForm = [userDetails.numTel];

    const fetchData = await postData(endpoint, dataForm);
    console.log("linkUserTag", tagName, dataForm, fetchData);
    let result = "";
    if (fetchData !== ERROR) {
      result = "success";
    } else {
      result = ERROR;
    }
    return result;
  };
  const linkUserEntite = async (entiteName) => {
    const endpoint = `Entite/${entiteName}/link/user`;
    const dataForm = [userDetails.numTel];
    const fetchData = await postData(endpoint, dataForm);

    // console.log("linkUserEntite", entiteName, dataForm, fetchData);
    let result = "";
    if (fetchData !== ERROR) {
      result = "success";
    } else {
      result = ERROR;
    }
    return result;
  };
  const activateUser = async () => {
    const endpoint = "Users/activate";
    const dataForm = [userDetails.numTel];
    const fetchData = await postData(endpoint, dataForm);
    // console.log("activateUser dddd", fetchData);
    if (fetchData !== ERROR) {
      // const listEntity = { ...userDetails.direction, ...userDetails.sructurePrivee };
      /* let linkDirection = null;
      let linkStructurePrivee = null;
      if (Object.keys(userDetails.direction).length !== 0) {
        linkDirection = await linkUserEntite(userDetails.direction.label);
      }
      if (Object.keys(userDetails.structurePrivee).length !== 0) {
        linkStructurePrivee = await linkUserEntite(userDetails.structurePrivee.label);
      }
      console.log("linkDirection dddd", linkDirection);
      console.log("linkStructurePrivee dddd", linkStructurePrivee);
      if (linkDirection !== ERROR && linkStructurePrivee !== ERROR) {
        goToMain();
      } */
      let linkEntity = null;
      if (Object.keys(userDetails.entity).length !== 0) {
        linkEntity = await linkUserEntite(userDetails.entity.label);
      }
      let linkTag = null;
      if (Object.keys(userDetails.userTags).length !== 0) {
        linkTag = await linkUserTag(userDetails.userTags.name);
      }
      if (linkEntity !== ERROR && linkTag !== ERROR) {
        goToMain();
      }
    }
  };
  const saveRole = async () => {
    const endpoint = "Roles/assignUsersRoles";

    // const dataForm = valuesForm.newRoles;
    let role = "";
    if (niveau === "1") {
      role = SUPERVISEUR_ROLE;
    } else if (niveau === "2") {
      role = ADMIN_REGION_ROLE;
    } else if (niveau === "3") {
      role = ADMIN_DEPARTEMENTAL_ROLE;
    } else if (niveau === "4I") {
      role = AGENT_ROLE;
    } else if (niveau === "4R") {
      role = RESPONSABLE_FERME;
    } else if (niveau === "5E") {
      role = ELEVEUR;
    } else if (niveau === "5P") {
      role = PROPRIETAIRE;
    }
    const roleUser = [
      {
        roleName: role,
        users: [
          {
            numTel: userDetails.numTel,
          },
        ],
      },
    ];
    const fetchData = await postData(endpoint, roleUser);
    console.log("fetchData", fetchData, roleUser);
    if (fetchData !== ERROR) {
      activateUser();
    }
  };
  const handleInsertUser = async () => {
    const endpoint = "auth/register";
    userDetails.password = "123456";
    // userDetails.entity = "123456";
    // userDetails.password = "123456";
    console.log("saveData user", userDetails);
    const fetchData = await postData(endpoint, userDetails);
    console.log("new user", fetchData);
    if (fetchData.access_token) {
      saveRole();
    }
  };
  const checkFormValues = () => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "error",
    };
    if (!userDetails?.prenom) {
      loadData = {
        isAlert: true,
        message: "Veuillez saisir le prenom",
        isLoading: false,
        alertSeverity: "error",
      };
    } else if (!userDetails?.nom) {
      loadData = {
        isAlert: true,
        message: "Veuillez saisir le nom",
        isLoading: false,
        alertSeverity: "error",
      };
    } else if (!userDetails?.numTel || userDetails?.numTel?.length !== 10) {
      loadData = {
        isAlert: true,
        message: "Veuillez saisir un numéro de téléphone valide",
        isLoading: false,
        alertSeverity: "error",
      };
    } else if (
      !userDetails?.lieu?.codeCommune ||
      !userDetails?.lieu?.codeRegion ||
      !userDetails?.lieu?.codeDepart ||
      !userDetails?.lieu?.id
    ) {
      loadData = {
        isAlert: true,
        message: "Veuillez saisir un lieu : Region + Departement + Commune",
        isLoading: false,
        alertSeverity: "error",
      };
    } else {
      handleInsertUser();
    }
    handleLoad(loadData);
  };

  const fetchLieuData = async () => {
    const data = await getLieu(getData);
    setLieuClassified(data);
  };
  const fetchProfilData = async () => {
    let niveauUser = "";
    if (user) {
      niveauUser = userDetails?.niveau;
    } else if (niveau) {
      niveauUser = niveau;
    }
    const customListProfil = await fetchCustomProfil(niveauUser);
    setListProfil(customListProfil);
  };
  const fetchEntityData = async () => {
    const data = await getEntity(getData);
    setListEntity(data);

    /* const directionData = data.filter((i) => i.type === TYPE_DIRECTION);
    setListDirection(directionData);

    const structureData = data.filter((i) => i.type === TYPE_STRUCTURE_PRIVEE);
    setListStructurePrivee(structureData); */
  };
  const fetchTagData = async () => {
    const data = await getTag(getData);
    setListTag(data);
  };

  useEffect(() => {
    fetchLieuData();
    fetchProfilData();
    fetchEntityData();
    fetchTagData();
  }, []);
  // console.log("ddd///", niveau);
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Typography variant="h6">Informations de l&apos;utilisateurs</Typography>

      <TextField
        label="Prénom *"
        name="prenom"
        value={userDetails.prenom}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled={user !== undefined}
      />
      <TextField
        label="Nom *"
        name="nom"
        value={userDetails.nom}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled={user !== undefined}
      />

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="telephone"
        label="Numéro de téléphone *"
        name="numTel"
        type="number"
        onChange={handleInputChange}
        value={userDetails?.numTel ?? ""}
        inputProps={{ maxLength: 10 }}
        disabled={user !== undefined}
      />
      {lieuClassified?.listRegion && (
        <AutoCompleteComponent
          options={lieuClassified?.listRegion}
          name="region"
          label="Region *"
          value={userDetails?.lieu?.region ?? ""}
          onChange={handleInputChange}
          isResultObject={false}
          isDisabled={user !== undefined}
        />
      )}

      {lieuClassified?.listDepartement && (
        <AutoCompleteComponent
          options={lieuClassified?.listDepartement}
          name="departement"
          label="Département *"
          value={userDetails?.lieu?.departement ?? ""}
          onChange={handleInputChange}
          isResultObject={false}
          isDisabled={user !== undefined}
        />
      )}

      {lieuClassified?.listCommune && (
        <AutoCompleteComponent
          options={lieuClassified?.listCommune}
          name="commune"
          label="Commune *"
          value={userDetails?.lieu?.commune ?? ""}
          onChange={handleInputChange}
          isResultObject={false}
          isDisabled={user !== undefined}
        />
      )}
      {listProfil && (
        <AutoCompleteComponent
          options={listProfil}
          name="fonction"
          label="Profil"
          value={userDetails?.fonction ?? ""}
          onChange={handleInputChange}
          isResultObject={false}
          isDisabled={user !== undefined}
        />
      )}
      {/* listDirection && (
        <AutoCompleteComponent
          options={listDirection}
          name="direction"
          label="Direction"
          value={userDetails?.direction ?? ""}
          onChange={handleInputChange}
          isResultObject
          isDisabled={user !== undefined}
        />
      )}
      {listStructurePrivee && (
        <AutoCompleteComponent
          options={listStructurePrivee}
          name="structurePrivee"
          label="Structure Privée"
          value={userDetails?.structurePrivee ?? ""}
          onChange={handleInputChange}
          isResultObject
          isDisabled={user !== undefined}
        />
      ) */}
      {listEntity && (
        <AutoCompleteComponent
          options={listEntity}
          name="entity"
          label="Structure Privée / Direction"
          value={userDetails?.entity?.name ?? ""}
          onChange={handleInputChange}
          isResultObject
          isDisabled={user !== undefined}
        />
      )}

      {listTag && (
        <AutoCompleteComponent
          options={listTag}
          name="userTags"
          label="Projet"
          value={userDetails?.userTags?.[0]?.tag?.name ?? userDetails?.userTags?.name ?? ""}
          onChange={handleInputChange}
          isResultObject
          isDisabled={user !== undefined}
        />
      )}
      {user === "" && (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {user ? (
            <MDButton type="submit" variant="gradient" color="info" onClick={handleUpdateUser}>
              Modifier
            </MDButton>
          ) : (
            <MDButton type="submit" variant="gradient" color="info" onClick={handleInsertUser}>
              Enregistrer
            </MDButton>
          )}
        </Box>
      )}
      {!user && (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <MDButton type="submit" variant="gradient" color="info" onClick={checkFormValues}>
            Enregistrer
          </MDButton>
        </Box>
      )}
    </Box>
  );
};

export default UserDetails;
