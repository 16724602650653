// import { ERROR } from "constant/message";
import { MODULE_DEMANDE_IDENTIFICATION } from "constant/moduleInfo";
// import useArrayOperation from "hooks/useArrayOperation";

// const { sortArray } = useArrayOperation();
const createDemandeIdentification = async (postData, newData) => {
  console.log("dddd", MODULE_DEMANDE_IDENTIFICATION);
  const fetchData = await postData(MODULE_DEMANDE_IDENTIFICATION, newData);
  /* let result = [];
  if (fetchData !== ERROR) {
    result = sortArray(fetchData, "name");
  } */
  return fetchData;
};
export default createDemandeIdentification;
