import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  // Button,
} from "@mui/material";
import getUserbyRole from "services/userService";
import PropTypes from "prop-types";
import AutoCompleteComponent from "components/AutoCompleteComponent";
import { roleN5E, roleN5P } from "constant/role";
import handleSelectChange from "helpers/selectHelper";
import getEspece from "services/especeService";
import MDButton from "components/MDButton";
import createDemandeIdentification from "services/demandeIdentificationService";
import { ERROR } from "constant/message";
import { useNavigate } from "react-router-dom";
import { LAST_PATH } from "constant";
import AnimalPerson from "./AnimalPerson";

const NewDemandeIdentificationComponent = ({ propsDataConfig }) => {
  const { ApiService, handleLoad } = propsDataConfig;
  const navigate = useNavigate();
  const [listUser, setListUser] = useState([]);
  const [listEspece, setListEspece] = useState([]);
  const [newDemande, setNewDemande] = useState({});
  const [userInfo, setUserInfo] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("dddda", name, value);
    //  const updatedDemande = { ...newDemande, [name]: value };
    let updatedDemande = {};
    if (value.isSelect) {
      // if (["fonction ", "entity", "userTags"].includes(name)) {
      updatedDemande = handleSelectChange(newDemande, name, value);
    } else {
      updatedDemande = { ...newDemande, [name]: value };
    }
    setNewDemande(updatedDemande);
    if (name === "user") {
      const selectedUser = {
        info: value.newValue,
      };
      setUserInfo(selectedUser);
    }
  };

  const fetchUser = async () => {
    const listTargetRole = [...roleN5E, ...roleN5P];
    const data = await getUserbyRole(ApiService.getDataUser, listTargetRole);
    setListUser(data);
  };
  const fetchEspece = async () => {
    const data = await getEspece(ApiService.getData);
    const resultEspece = data.map((i) => ({
      espece: i,
      count: 0,
      // especeId: i.id,
    }));
    setListEspece(resultEspece);
    //
  };
  useEffect(() => {
    fetchUser();
    fetchEspece();
  }, []);
  // console.log("aaaa", propsDataConfig);
  const checkForm = async (EspeceEffectif) => {
    if (newDemande.user) {
      const data = [
        {
          status: "INITIALIZED",
          // userId: newDemande.user.id,
          user: newDemande.user,
          effectifs: EspeceEffectif,
        },
      ];
      // console.log("new demande ", data);
      const result = await createDemandeIdentification(ApiService.postData, data);
      console.log("aaaa", result);
      if (result !== ERROR) {
        const loadData = {
          isAlert: true,
          message: "Opération effectuée avec succès",
          isLoading: false,
          alertSeverity: "success",
        };
        handleLoad(loadData);
        setTimeout(() => {
          const lastPath = localStorage.getItem(LAST_PATH);
          navigate(lastPath);
          // navigate(PATH_DEMANDE_IDENTIFICATION);
        }, 200);
      }
    } else {
      const loadData = {
        isAlert: true,
        message: "Veuillez sélectionner un Demandeur",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    }
  };
  // console.log("ddddd", newDemande?.user);
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Typography variant="h6">Informations de la Demande d&apos;identification</Typography>
      {listUser && (
        <AutoCompleteComponent
          options={listUser}
          name="user"
          label="Eleveur / Propriétaire"
          value={newDemande?.user ?? ""}
          onChange={handleInputChange}
          isResultObject
          // isDisabled={user !== undefined}
        />
      )}
      {newDemande.user && <AnimalPerson person={userInfo} />}
      {listEspece.length > 0 && (
        <EspeceCount list={listEspece} handleLoad={handleLoad} checkForm={checkForm} />
      )}
    </Box>
  );
};
const EspeceCount = ({ list, handleLoad, checkForm }) => {
  const [rows, setRows] = useState(list);
  /* const [rows, setRows] = useState([
    { name: "Groupe A", effectif: 0 },
    { name: "Groupe B", effectif: 0 },
    { name: "Groupe C", effectif: 0 },
  ]); */

  const handleEffectifChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].count = parseInt(value, 10) || 0;
    setRows(updatedRows);
  };

  const handleSubmit = () => {
    const effectifTotal = rows.reduce((total, espece) => total + espece.count, 0);
    console.log("Tableau récupéré:", effectifTotal);
    if (effectifTotal === 0) {
      const loadData = {
        isAlert: true,
        message: "Veuillez saisir un effectif",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    } else {
      const especeEffectif = rows
        .filter((i) => i.count > 0)
        .map((i) => ({
          // espece: { codeEspece: i.espece.codeEspece },
          espece: i.espece,
          count: i.count,
          // especeId: i.id,
        }));
      checkForm(especeEffectif);
    }
  };
  // console.log("77777", rows);
  return (
    <TableContainer component={Paper} style={{ width: "100%" }}>
      <Table style={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "70%" }}>Espèces</TableCell>
            <TableCell style={{ width: "30%", textAlign: "right" }}>Effectifs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={row.espece?.label}>
              <TableCell component="th" scope="row" style={{ width: "70%" }}>
                {row.espece?.label}
              </TableCell>
              <TableCell align="right" style={{ width: "30%" }}>
                <TextField
                  type="number"
                  value={row.count}
                  onChange={(e) => handleEffectifChange(index, e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: "16px" }}
      >
        Récupérer le Tableau
      </Button> */}
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <MDButton type="submit" variant="gradient" color="info" onClick={handleSubmit}>
          Enregistrer
        </MDButton>
      </Box>
    </TableContainer>
  );
};

export default NewDemandeIdentificationComponent;
NewDemandeIdentificationComponent.propTypes = {
  propsDataConfig: PropTypes.object.isRequired,
};
EspeceCount.propTypes = {
  list: PropTypes.object.isRequired,
  handleLoad: PropTypes.func.isRequired,
  checkForm: PropTypes.func.isRequired,
};
